import React, { useEffect } from "react";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../firebase/firebaseConfig";
import axios from "axios";

async function sendTokenToBackend(token) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/fcm-token/`,
      {
        token: token,
      },
      config
    );
  } catch (error) {
    console.error("Error al enviar el token al backend:", error);
  }
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service worker registered:", registration);
    })
    .catch((error) => {
      console.error("Service worker registration failed:", error);
    });

}

async function requestPermission() {
  const existingToken = localStorage.getItem("fcm_token");
  if (existingToken) {
    return;
  }

  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    try {
      const token = await getToken(messaging, {
        vapidKey: "BBa0dq6XCmKF9hhNt5VfHBduCN3K8X9oUEWVYtUJgQz5i_4Ua2qbRQB6-g67hzI3I_11EYxfIKOa0Rc8QUMNJsI",
      });

      if (token) {
        await sendTokenToBackend(token);
        localStorage.setItem("fcm_token", token);
      }
    } catch (error) {
      console.error("Error al obtener el token de FCM:", error);
    }
  } else if (permission === "denied") {
    console.warn("Permiso de notificación denegado.");
  }
}

export default function SaveToken() {
  useEffect(() => {
    requestPermission();
  }, []);

  return null;
}
