// import ProfileDefault from '../../assets/img/profile/default.png';
import DayanaDuque from '../../assets/img/profile/dayana-duque.png';
import LilianaAnte from '../../assets/img/profile/liliana-ante.png';
import DianaAmaya from '../../assets/img/profile/diana-amaya.jpg';
// import WilliamLuengas from '../../assets/img/profile/william-luengas.jpg';
import JavierChaustre from '../../assets/img/profile/javier-chaustre.png';
import DarcyGarnica from '../../assets/img/profile/darcy-garnica.jpg';
import MarcelaRamon from '../../assets/img/profile/marcela-ramon.jpg';

const people = [
    {
      name: 'Dayana Duque Espinosa',
      role: 'Dirección General',
      imageUrl: DayanaDuque,
      imageAlt: 'Dayana Duque'
    },
    // {
    //   name: 'William Luengas García',
    //   role: 'Dirección de programas, proyectos y planeación estratégica',
    //   imageUrl: WilliamLuengas,
    //   imageAlt: 'William Luengas'
    // },
		{
      name: 'Liliana Ante de La Cuadra',
      role: 'Área de comunicaciones y marketing digital',
      imageUrl: LilianaAnte,
      imageAlt: 'Liiana Ante'
    },
		{
      name: 'Javier Danilo Chaustre',
      role: 'Área jurídica',
      imageUrl: JavierChaustre,
      imageAlt: 'Javier Chaustre'
    },
		{
      name: 'Darcy Yoreliz Garnica Mejía',
      role: 'Área de Taletos Humanos',
      imageUrl: DarcyGarnica,
      imageAlt: 'Darcy Garnica'
    },
  ]

  export default function Team() {
    return (
      <div className="bg-white">
        <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-3xl font-bold tracking-tight sm:text-4xl text-yellow-button text-center">Nuestro equipo</h2>
            </div>
            <div
              className="flex flex-wrap justify-center gap-4 max-w-7xl mx-auto"
            >
              {people.map((person, index) => (
                <div key={index} className=''>
                  <div className="flex flex-col items-center">
                    <div className="h-44 w-44 flex items-center rounded-full overflow-hidden border-4 border-yellow-button bg-white">
                      <img className="" src={person.imageUrl} alt={person.imageAlt} />
                    </div>
                    <div className="">
                      <div className="space-y-1 p-2 font-medium leading-6 text-center max-w-52">
                        <h3 className="text-sm md:text-base md:leading-tight text-gray-700">{person.name}</h3>
                        <p className="text-gray-400 text-sm">{person.role}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
